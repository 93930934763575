<template>
  <div class="w-full xl:flex  justify-center items-center">
  <div class="xl:w-2/3 justify-center  items-center">  <div class="p-4 bg-dark-blue text-white" >
    <div v-if="isNotEmbed">
      <div class="flex justify-center items-center">
    <img alt="Octavia logo" class="w-52 mb-4" src="https://docs.octavia.one/images/kit/logotype.png">
</div>

      <h1 class="text-3xl font-bold mb-4">AI Moderation Playground</h1>
    <h4 class="text-xl mb-6">This playground allows you to test the moderation AI by configuring rules and inputting your test content. Allowing you to see the output of the AI. This is a two step process, this process uses our in-house model octavia-moderation-002.</h4>
 </div>
  <div class="2xl:flex">
    
    <div class="mb-4 2xl:w-1/2">
      <label class="block text-lg mb-2">Test Message</label>
      <textarea v-model="message" class="w-full border h-32p-2 bg-gray-800 text-white rounded"></textarea>
    </div>

    <div class="my-4 flex space-x-2 overflow-y-auto 2xl:w-1/2 2xl:flex">
      <button @click="preset0" class="bg-gray-900 hover:bg-gray-600 p-2 rounded transition duration-300">Try a random message</button>
      <button @click="preset1" class="bg-red-900 hover:bg-gray-600 p-2 rounded transition duration-300">HATE</button>
      <button @click="preset2" class="bg-orange-900 hover:bg-gray-600 p-2 rounded transition duration-300">FUD</button>
      <button @click="preset3" class="bg-yellow-900 hover:bg-gray-600 p-2 rounded transition duration-300">PITCH SPAM</button>
      <button @click="preset4" class="bg-yellow-700 hover:bg-gray-600 p-2 rounded transition duration-300">SPAM</button>
      <button @click="preset5" class="bg-green-900 hover:bg-gray-600 p-2 rounded transition duration-300">CONVERSATION</button>
    </div>
    
  </div>
    <div class="mb-4 flex space-x-2 overflow-y-auto">
      <label class="block text-lg mb-2">Banned Content</label>

      </div>
    <!-- Checkboxes for every category of abuse, next to eachother -->
    <div class="mb-4 flex space-x-2 overflow-y-auto">
      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Religion</label>
      <input v-model="religionenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Politics</label>
      <input v-model="politicsenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Shill Soft</label>
      <input v-model="shillSoftenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Shill Hard</label>
      <input v-model="shillHardenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Sexual</label>
      <input v-model="sexualenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Self Harm</label>
      <input v-model="selfharmenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Pitch</label>
      <input v-model="pitchenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">FUD Hard</label>
      <input v-model="fudhardenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">FUD Soft</label>
      <input v-model="fudsoftenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Hate High</label>
      <input v-model="hatehighenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Hate Low</label>
      <input v-model="hatelowenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">

      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Hate Mid</label>
      <input v-model="hatemidenabled" type="checkbox" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">
    </div>


    <div class="mb-4 flex">
      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Profanity Filter<br /><span class="text-sm">0 = None, 1 = Light, 2 = Medium, 3 = Heavy Moderation</span></label>
      <input v-model="profanityFilter" type="range" min="0" max="3" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">
    </div>


    <div class="mb-4 flex ">
      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Banned Words</label>
      <input v-model="bannedWords" type="text" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">
    </div>
<!-- token names -->
    <div class="mb-4 flex ">
      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Token Names</label>
      <input v-model="tokenName" type="text" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">
    </div>
    <!-- project names -->
    <div class="mb-4 flex ">
      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Project Names</label>
      <input v-model="projectName" type="text" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">
    </div>
    <!-- websites -->

    <div class="mb-4 flex ">
      <label class="block text-lg mb-2 mt-2 xl:w-1/4">Websites</label>
      <input v-model="websites" type="text" class="w-full  xl:w-3/4 border p-2 bg-gray-800 text-white rounded">
    </div>

    <button @click="submit" :class="{ 'submitting-button': isSubmitting }" class="bg-blue-500 hover:bg-blue-600 text-white p-2 mt-4 rounded transition duration-300 w-full"> <span v-if="isSubmitting">Submitting...</span><span v-else>Test Message &raquo;</span></button>


    <div :class="statusClass" class="mt-4 p-4 rounded bg-gray-800 text-white">
      {{ status }}
    </div>


    <div v-if="hasResult" :class="resultBoxClass + ' mt-4 p-4 border-2 rounded  text-white'">
      <p class="text-lg mb-4">{{ resultHeader }}</p>
      <p class="text-lg mb-2 bold"><i>Message Type: <Strong>{{ displayClassification }}</Strong></i></p>
      <p v-if="displaySuccess == 'true'"><strong>Success:</strong> {{ displaySuccess }}</p>
     <hr style="opacity:0.5" class="p-2"> <p>AI Moderation Stage: {{ displayStage }}</p>
      <p v-if="displayRule">Rule: {{ displayRule }}</p>
      <p v-if="!displayErrors == 'None'">Errors: {{ displayErrors }}</p>
    </div>


    <div v-if="!hasVoted" class="mt-4  grid grid-cols-4 gap-4 mb-4 overflow-y-auto ">
      <span class="mt-4 col-span-2 w-1/2 p-2 whitespace-nowrap">Send Feedback:</span>

          <button @click="downvote" class="  w-4 bg-red-500 hover:bg-red-600 text-white p-2 mt-4 rounded transition duration-300 w-full">
        👎 Bad Moderation
      </button> 
      <button @click="upvote" class=" w-16 bg-green-500 hover:bg-green-600 text-white p-2 mt-4 rounded transition duration-300 w-full">
        👍 Good Moderation
      </button>
    </div>


    <p class="italic text-gray-400">
      Model: octavia-moderation-model-003
    </p>
  </div></div><iframe id="zapierIframe" style="display:none;"></iframe>

</div>

</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      threshold: 0.6,
      religionenabled: true,
      politicsenabled: true,
      shillSoftenabled: true,
      shillHardenabled: true,
      sexualenabled: true,
      selfharmenabled: true,
      pitchenabled: true,
      fudhardenabled: true,
      fudsoftenabled: true,
      hatehighenabled: true,
      hatelowenabled: true,
      hatemidenabled: true,
      projectenabled: false,
      cryptoenabled: false,
      generalenabled: false,
      supportenabled: false,
      convoenabled: false,
      tokenName: "GEMS, $GEMS, Gempad Token",
      websites: "https://gempad.app, https://t.me/TheGemPad",
      projectName: "Gempad, The Gempad, Alpha Club",
      message: 'Gm! 🌞',
      bannedWords: "rugging, scam, business proposal",
      result: null,
      status: 'Ready',
      statusClass: 'bg-grey-600 text-white',
      isSubmitting: false,
      profanityFilter: 0,
      hasVoted: true,
    };
  },
  computed: {
    isNotEmbed() {
      const params = new URLSearchParams(window.location.search);
      return params.get('embed') !== 'true';
    },
  hasResult() {
    return this.result && this.result.data;
  },
  displaySuccess() {
    return this.result?.success;
  },
  displayStage() {
    return this.result?.data?.stage;
  },
  displayViolation() {
    return this.result?.data?.violation;
  },
  displayClassification() {
    return this.result?.data?.label;
  },
  displayRule() {
    return this.result?.data?.rule;
  },
  displayErrors() {
    return this.result?.data?.errors?.length ? this.result.data.errors.join(', ') : 'None';
  },
  resultBoxClass() {
  return this.displayViolation ? 'bg-dark-red border-red-500' : 'bg-dark-green border-green-500';
},
resultHeader() {
  return this.displayViolation 
    ? "Rules Violated - This content violates the rules set." 
    : "Safe Content - This content either does not violate the rules, or has been determined to be unlikely to violate a reasonable communities rules by our internally trained model.";
}
},
  methods: {
    downvote() {
      this.hasVoted = true;
      this.status = "Feedback Recorded. Thank you, this will help us improve our models."

      const iframe = document.getElementById('zapierIframe');
      const params = new URLSearchParams({
        message: this.message,
        rules: this.rules,
        bannedWords: this.bannedWords,
        classification: this.displayClassification,
        violation: this.displayViolation,
        success: this.displaySuccess,
        errors: this.displayErrors,
        label: "downvote"

      });


      iframe.src = 'https://hooks.zapier.com/hooks/catch/1588098/3r743k9/?' + params.toString();
},

upvote() {
  this.hasVoted = true;

  this.status = "Positive Feedback Recorded. Thank you, this will help us improve our models."

  const iframe = document.getElementById('zapierIframe');

  const params = new URLSearchParams({
    message: this.message,
    rules: this.rules,
    bannedWords: this.bannedWords,
    classification: this.displayClassification,
    violation: this.displayViolation,
    success: this.displaySuccess,

    errors: this.displayErrors,
    label: "upvote"
  });

  iframe.src = 'https://hooks.zapier.com/hooks/catch/1588098/3r743k9/?' + params.toString();
},
async submit() {

      // Turn comma/space seperated string of words into array (bannedWordList)
      let bannedWordsArray = [];
      if (this.bannedWords) {
        bannedWordsArray = this.bannedWords.split(/[\s,]+/);
      }

      // do same for projectNameArray, tokenNameArray, websitesArray
      let projectNameArray = [];
      if (this.projectName) {
        projectNameArray = this.projectName.split(/[\s,]+/);
      }
      let tokenNameArray = [];
      if (this.tokenName) {
        tokenNameArray = this.tokenName.split(/[\s,]+/);
      }
      let websitesArray = [];
      if (this.websites) {
        websitesArray = this.websites.split(/[\s,]+/);
      }
    this.profanityFilter = parseInt(this.profanityFilter);

        this.isSubmitting = true;
        this.result = null;
      this.status = 'Awaiting Response...';
      this.statusClass = 'text-yellow-500 ';
      try {
        //setup data object in typescript
        let thedata = {
            message: this.message,
            categories: {
          religion: {
            confidence: 0.8,
                rules: [],
                rulesEnabled: true,
                enabled: this.religionenabled
          },
          politics: {
            confidence: 0.7,
                rules: [],
                rulesEnabled: false,
                enabled: this.politicsenabled
          },
          shillSoft: {
            confidence: 0.6,
                rules: [],
                rulesEnabled: true,
                enabled: this.shillSoftenabled
          },
          shillHard: {
            confidence: 0.9,
                rules: [],
                rulesEnabled: false,
                enabled: this.shillHardenabled
          },
          sexual: {
            confidence: 0.5,
                rules: [],
                rulesEnabled: true,
                enabled: this.sexualenabled
          },
          selfharm: {
            confidence: 0.4,
                rules: [],
                rulesEnabled: false,
                enabled: this.selfharmenabled
          },
          pitch: {
            confidence: 0.3,
                rules: [],
                rulesEnabled: true,
                enabled: this.pitchenabled
          },
          fudhard: {
            confidence: 0.2,
                rules: [],
                rulesEnabled: false,
                enabled: this.fudhardenabled
          },
          fudsoft: {
            confidence: 0.1,
                rules: [],
                rulesEnabled: true,
                enabled: this.fudsoftenabled
          },
          hatehigh: {
            confidence: 0.95,
                rules: [],
                rulesEnabled: false,
                enabled: this.hatehighenabled
          },
          hatelow: {
            confidence: 0.85,
                rules: [],
                rulesEnabled: true,
                enabled: this.hatelowenabled
          },
          hatemid: {
            confidence: 0,
                rules: [],
                rulesEnabled: true,
                enabled: this.hatemidenabled
          },
          project: {
            confidence: 0,
                rules: [],
                rulesEnabled: false,
                enabled: false
          },
          crypto: {
            confidence: 0,
                rules: [],
                rulesEnabled: true,
                enabled: this.cryptoenabled
          },
          general: {
            confidence: 0,
                rules: [],
                rulesEnabled: true,
                enabled: false
          },
          support: {
            confidence: 0,
                rules: [],
                rulesEnabled: true,
                enabled: this.supportenabled
          },
        },
        profanityFilter: this.profanityFilter,
            projectName: projectNameArray,
            "tokenName": tokenNameArray,
            "websites": websitesArray,
            "bannedWordlist": bannedWordsArray,
      };
      console.log(thedata)
      const response = await axios.post('https://api.moderation.ai.octavialabs.io/api/moderate', thedata, {
              headers: {
                'Authorization': 'Bearer testingapi'
              }
            }
          );
      this.result = response.data; // Store the response data as an object
        console.log(this.result);
      this.status = 'Ready';
      this.hasVoted = false;
      this.statusClass = 'text-green-500 ';
    } catch (error) {
      console.log("error");
    this.status = 'Failed - Ensure you\'re not spamming the playground, and try again in a few seconds.';
    this.statusClass = 'text-red-500 ';
    if (error.response) {
      console.log(error);
      this.result = error.response.data; // Store the error response data as an object
      this.status += "(" + error.response.data + ")"
      this.isSubmitting = false;

    } else {
      // Handle other errors (e.g., network errors)
      this.result = { message: error.message }; // Store the error message as an object
      this.status += "(" + error.message + ")"
      this.isSubmitting = false;

    }
    
  const iframe = document.getElementById('zapierIframe');

  const params = new URLSearchParams({
    message: this.message,
    rules: this.rules,
    bannedWords: this.bannedWords,
    classification: this.displayClassification,
    violation: this.displayViolation,
    success: this.displaySuccess,
    errors: this.displayErrors,
    label: "default"
  });

  iframe.src = 'https://hooks.zapier.com/hooks/catch/1588098/3r743k9/?' + params.toString();
  }
  setTimeout(() => {
      this.isSubmitting = false;
      

    }, 2500);
},
preset1() {
      let presets = [
        'Hey man, I hate you and all the people of your race.',
        ' am the best at racism'
    ];
      const randomIndex = Math.floor(Math.random() * presets.length);
      this.message = presets[randomIndex];
    },
    preset0() {
      let presets = [
        'Hey man, I hate you and all the people of your race.',
        ' am the best at racism',
        'This Project is dumping, going to $0.',
        'I am dumping all my fucking tokens',
        'Devs are selling, this token is rugging',
        'Scam project.',
        'Hello sir, I am a senior blockchain developer looking for work with your amazing Gempad community. Please DM.me.',
        'I Have a business proposal about creating an Arabic telegram community. Who should I dm?',
        'Hello, I am a Persian-speaking ambassador and marketer with more than 4 years of experience in marketing, guidance and moderator at Telegram. I request the establishment of a local Persian association for countries (Iran🇮🇷, Afghanistan🇦🇫, Tajikistan🇹🇯) Who should I talk to?',
        'I can help this project with listing on different exchanges.',
        'Hey Team, I had a great experience with a marketing company that helped me promote my crypto projects. They offer a wide range of services, including CMC & CG listings and trending, social media & digital marketing, article placements on top financial and crypto websites, billboard advertising, AMA sessions, email marketing, and more. I think their marketing package can help the project gain exposure and attract potential customers. Let me know if you\'re interested, and I can provide you with the details.',
        'Hello Admin  I am graphic designer, I can create different kinds of graphic art all design Like illustration vector art, Stickers(static/animated)NFTs, Logos, Posters, Banners, Gifs, promotions videos, Whitepaper,  and more...  Please reply I need work',
        'Hi , I have an invitation to participate in Crypto conference . Who can I contact?',
        'Join our exclusive Telegram group for the hottest crypto signals and make huge profits! 🚀💰 t.me/bobsburgers',
        'The Death Airdrop is on now 🗄Аirdrop pаge: getDeath.com/Drop/   It\'s possible for everyone to claim free $DEATH.  Connect your wallet, and gain tokens now!',
        'Please use my affiliate link https://aff.ly/go329j',
        'Get 10x returns on your investment in just 24 hours! Limited slots available, don\'t miss out! 📈💸 DM ME NOW',
        'I\'m holding fine. Thanks for asking.',
        'Which one are you referring to..there\'s so much ongoing everytime 😁',
        'the truth is very strange. there have already been many discord hacked',
        'Goodnight everyone, see you tomorrow!',  'Goodnight everyone, see you tomorrow!',
        'It\'s a good morning, gm!',
        'Morning fam 🤩😊',
        'Goodnight everyone, see you tomorrow!',
        'We are a great team',
        'Yes, whenever you communicate with blockchain you pay gas',
        'I wonder how many new users in bsc or Tron networks ... If you can have the data would be interesting to compare',
        'That\'s not a rug, so did the initial owners of bonfire and safemoon, will you call them rugged? Even though their projects still went on to do great.',
        'The federal reserve needs to stop printing money or we\'re all gonna die',


    ];
      const randomIndex = Math.floor(Math.random() * presets.length);
      this.message = presets[randomIndex];
    },
    preset2() {

      let presets = [
        'This Project is dumping, going to $0.',
        'I am dumping all my fucking tokens',
        'Devs are selling, this token is rugging',
        'Scam project.',
      ];
      const randomIndex = Math.floor(Math.random() * presets.length);
      this.message = presets[randomIndex];
        },

    preset3() {

      let presets = [
        'Hello sir, I am a senior blockchain developer looking for work with your amazing Gempad community. Please DM.me.',
        'I Have a business proposal about creating an Arabic telegram community. Who should I dm?',
        'Hello, I am a Persian-speaking ambassador and marketer with more than 4 years of experience in marketing, guidance and moderator at Telegram. I request the establishment of a local Persian association for countries (Iran🇮🇷, Afghanistan🇦🇫, Tajikistan🇹🇯) Who should I talk to?',
        'I can help this project with listing on different exchanges.',
        'Hey Team, I had a great experience with a marketing company that helped me promote my crypto projects. They offer a wide range of services, including CMC & CG listings and trending, social media & digital marketing, article placements on top financial and crypto websites, billboard advertising, AMA sessions, email marketing, and more. I think their marketing package can help the project gain exposure and attract potential customers. Let me know if you\'re interested, and I can provide you with the details.',
        'Hello Admin  I am graphic designer, I can create different kinds of graphic art all design Like illustration vector art, Stickers(static/animated)NFTs, Logos, Posters, Banners, Gifs, promotions videos, Whitepaper,  and more...  Please reply I need work',
        'Hi , I have an invitation to participate in Crypto conference . Who can I contact?',
      ];
      const randomIndex = Math.floor(Math.random() * presets.length);
      this.message = presets[randomIndex];
        },   
       

    preset4() {

let presets = [
  'Join our exclusive Telegram group for the hottest crypto signals and make huge profits! 🚀💰 t.me/bobsburgers',
  'The Death Airdrop is on now 🗄Аirdrop pаge: getDeath.com/Drop/   It\'s possible for everyone to claim free $DEATH.  Connect your wallet, and gain tokens now!',
  'Please use my affiliate link https://aff.ly/go329j',
  'Get 10x returns on your investment in just 24 hours! Limited slots available, don\'t miss out! 📈💸 DM ME NOW',
];
const randomIndex = Math.floor(Math.random() * presets.length);
this.message = presets[randomIndex];
  },   


  preset5() {

let presets = [
  'I\'m holding fine. Thanks for asking.',
  'Which one are you referring to..there\'s so much ongoing everytime 😁',
  'the truth is very strange. there have already been many discord hacked',
  'Goodnight everyone, see you tomorrow!',  'Goodnight everyone, see you tomorrow!',
  'It\'s a good morning, gm!',
  'Morning fam 🤩😊',
  'Goodnight everyone, see you tomorrow!',
  'We are a great team',
  'Yes, whenever you communicate with blockchain you pay gas',
  'I wonder how many scams in bsc or Tron networks ... If you can have the data would be interesting to compare',
  'That\'s not a rug, so did the initial owners of bonfire and safemoon, will you call them rugged? Even though their projects still went on to do great.',
  'The federal reserve needs to stop printing money or we\'re all gonna die',

];
const randomIndex = Math.floor(Math.random() * presets.length);
this.message = presets[randomIndex];
  },   

      

  }
};
</script>

<style scoped>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.bg-dark-red {
  background-color: #8b0000;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}.submitting-button {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
      color:#eee;
}
.border-red-500 {
  border-color: #f56565;
}

.bg-dark-green {
  background-color: #006400;
}

.border-green-500 {
  border-color: #48bb78;
}button:disabled {
  cursor: not-allowed;
  opacity:0.9;

}

</style>
